import router from "@/router";
import axios from "axios";
import { EventHelper } from "@/helpers/eventHelper";

const dafisSessionTokenKey = "dafis-session-token";
const dafisReloginTokenKey = "dafis-relogin-token";
let reloginRequest;

class SessionHelpers {
  async login(email, password, rememberMe = false) {
    if (email && email !== "" && password && password !== "") {
      const encodedPassword = btoa(("fjhdhsTG7fmelklskf9" + password + "pol,wsmdWGOnbw7uj").split("").reverse().join(""));
      const response = await axios.post(
        `${import.meta.env.VITE_DAFIS_API_URL}/Authentication?code=${import.meta.env.VITE_APIKEY_AUTHENTICATION}`,
        {
          email,
          password: encodedPassword
        }
      );

      if (response.status === 200 && response.data) {
        sessionStorage.setItem(dafisSessionTokenKey, response.data.token);
        if (rememberMe) {
          localStorage.setItem(dafisReloginTokenKey, response.data.reloginToken);
        } else {
          sessionStorage.setItem(dafisReloginTokenKey, response.data.reloginToken);
        }

        EventHelper.emit("userSessionChanged", this.amIAuthenticated);
        return true;
      } else {
        EventHelper.emit("userSessionChanged", this.amIAuthenticated);
        return false;
      }
    }
    EventHelper.emit("userSessionChanged", this.amIAuthenticated);
    return false;
  }

  async relogin() {
    if (reloginRequest) {
      return reloginRequest;
    }

    // eslint-disable-next-line no-async-promise-executor
    reloginRequest = new Promise(async resolve => {
      const reloginTokenFromLocalStorage = localStorage.getItem(dafisReloginTokenKey);
      const reloginTokenFromSessionStorage = sessionStorage.getItem(dafisReloginTokenKey);

      if (reloginTokenFromLocalStorage == null && reloginTokenFromSessionStorage == null) {
        this.logout();
        resolve(false);
        return;
      }

      try {
        const response = await axios.post(
          `${import.meta.env.VITE_DAFIS_API_URL}/Authentication/relogin?code=${import.meta.env.VITE_APIKEY_AUTHENTICATION}`,
          {
            reloginToken: reloginTokenFromLocalStorage ? reloginTokenFromLocalStorage : reloginTokenFromSessionStorage
          }
        );

        if (response.status === 200 && response.data) {
          sessionStorage.setItem(dafisSessionTokenKey, response.data.token);

          if (reloginTokenFromLocalStorage) {
            localStorage.setItem(dafisReloginTokenKey, response.data.reloginToken);
          } else {
            sessionStorage.setItem(dafisReloginTokenKey, response.data.reloginToken);
          }

          EventHelper.emit("userSessionChanged", this.amIAuthenticated);
          resolve(true);
        } else {
          this.logout();
          resolve(false);
        }
      } catch (error) {
        switch (error.message) {
          case "Network Error":
            // Handle network error
            break;

          default:
            // Handle error
            break;
        }
        // Handle error
        this.logout();
        resolve(false);
      }
    });

    reloginRequest.finally(() => {
      reloginRequest = null;
    });

    return reloginRequest;
  }

  async loginFromOneTimeLoginToken(token) {
    try {
      const response = await axios.post(
        `${import.meta.env.VITE_DAFIS_API_URL}/Authentication/onetimelogin?code=${import.meta.env.VITE_APIKEY_AUTHENTICATION}`,
        {
          reloginToken: token
        }
      );
      if (response.status === 200 && response.data) {
        sessionStorage.setItem(dafisSessionTokenKey, response.data.token);
        EventHelper.emit("userSessionChanged", this.amIAuthenticated);
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  }

  logout() {
    sessionStorage.removeItem(dafisReloginTokenKey);
    sessionStorage.removeItem(dafisSessionTokenKey);
    localStorage.removeItem(dafisReloginTokenKey);
    localStorage.removeItem(dafisSessionTokenKey);
    EventHelper.emit("userSessionChanged", this.amIAuthenticated);

    if (router.currentRoute.value?.meta?.authenticationNeeded) {
      router.push({ name: "login" });
    }
  }

  get accessToken() {
    return sessionStorage.getItem(dafisSessionTokenKey);
  }

  get amIAuthenticated() {
    return sessionStorage.getItem(dafisSessionTokenKey) !== null;
  }
}

export const SessionHelper = new SessionHelpers();
