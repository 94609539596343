import { createRouter, createWebHistory } from "vue-router";
import { SessionHelper } from "@/helpers/sessionHelper";
import i18n from "@/helpers/i18nHelper";
import { useMeStore } from "@/stores/meStore";
import { USER_ROLE } from "@/models/user";

const { t } = (await i18n()).global;

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: "home",
      component: () => import("@/views/HomeView.vue"),
      meta: {
        title: "menu.home",
        authenticationNeeded: true,
        roles: [USER_ROLE.OWNER, USER_ROLE.SUPERUSER]
      }
    },
    {
      path: "/registrations",
      name: "registrations",
      component: () => import("@/views/registration/registrationsView2.vue"),
      meta: {
        title: "menu.registrations.registrations",
        authenticationNeeded: true
      }
    },
    {
      path: "/registrations/:registrationId",
      name: "registrationEdit",
      component: () => import("@/views/registration/registrationEditView.vue"),
      props: true,
      meta: {
        title: "menu.registrations.edit-registration",
        authenticationNeeded: true,
        includeReturnPath: true
      }
    },
    {
      path: "/registration/:contactId?/:jobId?/:date?",
      name: "registrationNew",
      component: () => import("@/views/registration/registrationEditView.vue"),
      props: true,
      meta: {
        title: "menu.registrations.new-registration",
        authenticationNeeded: true,
        includeReturnPath: true
      }
    },
    {
      path: "/jobs/edit/:jobId?",
      name: "jobEdit",
      component: () => import("@/views/job/jobEditView.vue"),
      props: true,
      meta: {
        title: "menu.jobs.edit-job",
        authenticationNeeded: true
      }
    },
    {
      path: "/jobs/:jobId",
      name: "jobView",
      component: () => import("@/views/job/jobView.vue"),
      props: true,
      meta: {
        title: "menu.jobs.edit-job",
        authenticationNeeded: true
      }
    },
    {
      path: "/jobs",
      name: "jobs",
      component: () => import("@/views/job/jobsView.vue"),
      props: true,
      meta: {
        title: "menu.jobs.jobs",
        authenticationNeeded: true
      }
    },
    {
      path: "/contacts/edit/:contactId?",
      name: "contactEdit",
      component: () => import("@/views/contact/contactEditView.vue"),
      props: true,
      meta: {
        title: "menu.contacts.edit-contact",
        authenticationNeeded: true
      }
    },
    {
      path: "/contacts/:contactId",
      name: "contactView",
      component: () => import("@/views/contact/contactView.vue"),
      props: true,
      meta: {
        title: "menu.contacts.contact",
        authenticationNeeded: true
      }
    },
    {
      path: "/contacts",
      name: "contactsView",
      component: () => import("@/views/contact/contactsView.vue"),
      props: true,
      meta: {
        title: "menu.contacts.contacts",
        authenticationNeeded: true
      }
    },
    {
      path: "/users",
      children: [
        {
          path: "",
          name: "users",
          component: () => import("@/views/user/usersView.vue"),
          props: true,
          meta: {
            title: "menu.user.users",
            authenticationNeeded: true,
            roles: [USER_ROLE.OWNER, USER_ROLE.SUPERUSER]
          }
        },
        {
          path: "new",
          name: "userNew",
          component: () => import("@/views/user/userEditView.vue"),
          meta: {
            title: "menu.user.new-user",
            authenticationNeeded: true,
            roles: [USER_ROLE.OWNER, USER_ROLE.SUPERUSER]
          }
        },
        {
          path: ":userId",
          name: "user",
          component: () => import("@/views/user/userView.vue"),
          props: true,
          meta: {
            title: "menu.user.user",
            authenticationNeeded: true,
            roles: [USER_ROLE.OWNER, USER_ROLE.SUPERUSER]
          }
        },
        {
          path: ":userId/edit",
          name: "userEdit",
          component: () => import("@/views/user/userEditView.vue"),
          props: true,
          meta: {
            title: "menu.user.user-edit",
            authenticationNeeded: true,
            roles: [USER_ROLE.OWNER, USER_ROLE.SUPERUSER]
          }
        }
      ]
    },

    {
      path: "/account/:status?",
      name: "accountView",
      component: () => import("@/views/settings/accountView.vue"),
      props: true,
      meta: {
        title: "menu.account.account",
        authenticationNeeded: true
      }
    },

    {
      path: "/invoicing",
      name: "invoicing",
      component: () => import("@/views/invoicing/invoicingView.vue"),
      meta: {
        title: "menu.jobs.jobs",
        authenticationNeeded: true,
        roles: [USER_ROLE.OWNER, USER_ROLE.SUPERUSER]
      }
    },

    {
      path: "/login",
      name: "login",
      component: () => import("@/views/extranet/LoginView.vue"),
      meta: {
        title: "menu.login",
        authenticationNeeded: false
      }
    },
    {
      path: "/restoremypassword/:restorePasswordRequestId?",
      name: "restoreMyPassword",
      component: () => import("@/views/extranet/RestoreMyPasswordView.vue"),
      meta: {
        title: "menu.restoremypassword",
        authenticationNeeded: false
      },
      props: true
    },
    {
      path: "/welcomenewuser/:restorePasswordRequestId",
      name: "welcomeNewUser",
      component: () => import("@/views/extranet/WelcomeNewUserView.vue"),
      meta: {
        title: "menu.welcomenewuser"
      },
      props: true
    },
    {
      path: "/signup/confirm/:confirmToken?",
      name: "signupConfirm",
      component: () => import("@/views/extranet/SignUp.vue"),
      meta: {
        title: "menu.signup.confirm",
        authenticationNeeded: false
      },
      props: true
    },
    {
      path: "/signup/:oneTimeLoginToken?",
      name: "signup",
      component: () => import("@/views/extranet/SignUp.vue"),
      meta: {
        title: "menu.signup",
        authenticationNeeded: false
      },
      props: true
    }
  ]
});

router.beforeEach(async (to, from, next) => {
  if (to.meta.authenticationNeeded) {
    if (!SessionHelper.amIAuthenticated) {
      // Is user not authenticated try relogin - else send to login page
      await SessionHelper.relogin();

      if (!SessionHelper.amIAuthenticated) {
        next({ name: "login" });
        return;
      }
    }
  } else {
    if (SessionHelper.amIAuthenticated) {
      next({ name: "home" });
      return;
    }
  }

  if (to.meta.roles) {
    const meStore = useMeStore();

    if (!to.meta.roles.includes(meStore.user.role)) {
      next({ name: "registrations" });
      return;
    }
  }

  if (to.meta.title) {
    document.title = `Dafis - ${t(to.meta.title)}`;
  }

  if (to.meta.includeReturnPath) {
    if (!to.query.returnPath) {
      const query = { ...to.query, returnPath: from.fullPath };
      next({ ...to, query });
      return;
    }
  }
  next();
});

export default router;
